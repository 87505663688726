import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from "../views/layout.vue";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy.vue";

const routes = [
  {
    path: '/',
    name: 'app',
    component: Layout,
    children: [
      {
        name: 'Start',
        path: '',
        component: HomeView
      },      
    ]
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
