import axios from 'axios';
// import router from '../router/index';

/** Базовая конфигурация axios */
let config = {
    // Базовый URL-адрес сервера
    baseURL: 'https://NitrosDataAPI.nitrosbase.com/API/SendContactMail2'
};

/** Создание экземпляра axios */
const httpClient = axios.create(config);

/** Добавление токена аутентификации, и обновление его, если это требуется */
// const authInterceptor = config => {
//     // достаём токент аутентификации пользователя, с LocalStorage, или cookies, например
//     const authToken = localStorage.getItem('token') || 'Not token';
//     config.headers.Authorization = `Bearer ${authToken}`;

//     return config;
// };

/** добавлени логгера при каждом axios запросе */
// const loggerInterceptor = config => {
//     /** как-то обрабатываем логи */
//     // console.log('loggerInterceptor', config);
//     return config;
// }

/** Добавляем экземпляру созданные обработчики для аутентификации и логов */
// httpClient.interceptors.request.use(authInterceptor);
// httpClient.interceptors.request.use(loggerInterceptor);

/** Добавление обработчика для результатов или ошибок при запросах */
// httpClient.interceptors.response.use(
//     response => {
//         /** Как-то обрабатываем успешный результат */
//         // console.log('Как-то обрабатываем успешный результат', response);
//         return response;
//     }

//     // error => {
//     //     console.log('httpClient.interceptors.response.use error', error);
//     //     /** Как-то обрабатываем результат, завершенный ошибкой */
        
//     //     if (error.response.status === 401 || error.response.status === 403) {
//     //         router.replace('/');
//     //     }

//     //     return error.response;
//     // }
// );

export { httpClient };
