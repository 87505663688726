<template>
    <v-row dense justify="center">
      <v-col id="content-wrapper"
      cols="8"      
      > 
        <h1 v-if="lang == 'en'" class="text-h4 mb-10 d-flex justify-center">NitrosData database products</h1>              
        <h1 v-if="lang == 'ru'" class="text-h5 mb-10 d-flex justify-center">Инструменты для работы с данными компании NitrosData</h1>              
        <div class="main-container">          
          <!-- <a class="main-img" href="#" target="_self" rel="noopener noreferrer" @click.prevent="$router.push('/sql'),changePage('sql')"> -->
          <a class="main-img" href="https://sql.nitrosbase.ru/" target="_blank" rel="noopener noreferrer">
            <v-img
              height="50"
              width="145"
              src="../assets/block1.svg"
            >
            </v-img>
            <div class="line sql"></div>
            <v-tooltip
              activator="parent"
              location="bottom"
              :height=130
              :width=300
              v-if="lang == 'en'"
            >
              High-performance relational database for modern IT solutions: high performance, reliability, scalability, distributed data processing, stored procedures, transaction support and many other advantages.
            </v-tooltip>
            <v-tooltip
              activator="parent"
              location="bottom"
              :height=170
              :width=300
              v-if="lang == 'ru'"
            >
              Высокопроизводительная реляционная СУБД, обладающая всеми качествами современных IT решений: высокая производительность, надежность, распределенная обработка, поддержка транзакций, масштабируемость и множество других достоинств.
            </v-tooltip>
          </a>
          <!-- <a class="main-img" href="#" rel="noopener noreferrer" @click.prevent="$router.push('/mm'),changePage('mm')"> -->
          <a class="main-img" href="https://mm.nitrosbase.ru/" target="_blank" rel="noopener noreferrer">
            <v-img
              height="50"
              width="145"
              src="../assets/block2.svg"
            >
            </v-img>
            <div class="line mm"></div>
            <v-tooltip
              activator="parent"
              location="bottom"
              :height=130
              :width=300
              v-if="lang == 'en'"
            >
              High-performance universal database for processing relational, graph and document data. Obtains high performance on all supported data models; provides scalability and reliability.
            </v-tooltip>
            <v-tooltip
              activator="parent"
              location="bottom"
              :height=150
              :width=350
              v-if="lang == 'ru'"
            >
              Высокопроизводительная универсальная СУБД для работы с реляционной, графовой, документной моделями данных. Обладает высокой производительностью на всех поддерживаемых моделях; Обеспечивает масштабируемость, надежность.
            </v-tooltip>
          </a>
          <!-- <a class="main-img" href="#" target="_self" rel="noopener noreferrer" @click.prevent="$router.push('/rdf'),changePage('rdf')"> -->
          <a class="main-img" href="https://rdf.nitrosbase.ru/" target="_blank" rel="noopener noreferrer">
            <v-img
              height="50"
              width="145"
              src="../assets/block3.svg"
            >
            </v-img>
            <div class="line rdf"></div>
            <v-tooltip
              activator="parent"
              location="top"
              :height=130
              :width=350
              v-if="lang == 'en'"
            >
              State of the art high-performance graph database, based on W3C semantic standards including RDF and SPARQL. Combines the flexibility of graph models and the power of high-performance databases.
            </v-tooltip>
            <v-tooltip
              activator="parent"
              location="top"
              :height=130
              :width=370
              v-if="lang == 'ru'"
            >
              Современная высокопроизводительная графовая база данных, основанная на семантических стандартах W3C включая RDF и SPARQL. Объединяет гибкость графовых моделей и мощь высокопроизводительных баз данных.
            </v-tooltip>
          </a>
          <!-- <a class="main-img" href="#" rel="noopener noreferrer" @click.prevent="$router.push('/im'),changePage('im')"> -->
            <a class="main-img" href="https://im.nitrosbase.ru/" rel="noopener noreferrer" target="_blank">
            <v-img
              height="50"
              width="145"
              src="../assets/block4.svg"
            >
            </v-img>
            <div class="line im"></div>
            <v-tooltip
              activator="parent"
              location="top"
              :height=130
              :width=350
              v-if="lang == 'en'"
            >
              High-performance data processing library, In-Memory database. Performance comparable to custom proprietary algorithms. Flexibility and convenience of SQL, fault tolerance, asynchronous saving data to disk.
            </v-tooltip>
            <v-tooltip
              activator="parent"
              location="top"
              :height=130
              :width=440
              v-if="lang == 'ru'"
            >
              Высокопроизводительная библиотека для работы с данными в памяти; резидентная база данных. Быстродействие сравнимо со специально разработанными проприетарными алгоритмами. Гибкость и удобство SQL, устойчивость к сбоям, сохранение результатов на диск.
            </v-tooltip>
          </a>
          <a class="center" href="https://nitrosdata.ru/" target="_blank" rel="noopener noreferrer">
            <img                        
              :src="getPic()"
              class="centerImg"
              >          
          </a>
        </div>
      </v-col>
    </v-row>
</template>

<script>
import "./HomeView.scss";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "HomeView",
  computed: {
    ...mapGetters(['CURENT_LANG']),
    ...mapState(['lang']),
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapMutations(['SET_PAGE','setPage']),
    getPic() {
      return require('../assets/center.svg')
    },  
    changePage(page) {
      this.$store.commit('SET_PAGE',page)
    }
  }
} 
</script>
